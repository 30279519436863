const config = {
  production: false,
  development: false,
  publicApiRoot: 'https://bahnkick-dev-api.azurewebsites.net/api',
  hubName: 'bahnkick-dms-notifications-sandbox',
  hubEndpoint:
    'Endpoint=sb://bahnkick.servicebus.windows.net/;SharedAccessKeyName=DefaultListenSharedAccessSignature;SharedAccessKey=JwQvCKwQJVKlaVY29p94hGmCI5PDJwUYl22nCJ1F6WI=',
};

export default config;
